import React, { useState } from "react";

function Display({ value }) {
  return (
    <div className="display">
      <input type="text" value={value} readOnly maxLength="12" />
    </div>
  );
}

function Buttons({ onButtonClick }) {
  const buttonValues = [
    "AC",
    "DE",
    "%",
    "/",
    "7",
    "8",
    "9",
    "*",
    "4",
    "5",
    "6",
    "-",
    "1",
    "2",
    "3",
    "+",
    "0",
    ".",
    "=",
  ];

  return (
    <div className="buttons">
      {buttonValues.map((val, index) => (
        <button
          className={`button ${val === "=" ? "equals" : ""}`}
          key={index}
          onClick={() => onButtonClick(val)}
        >
          {val}
        </button>
      ))}
    </div>
  );
}

export default function App() {
  const [value, setValue] = useState("");

  const handleButtonClick = (button) => {
    if (button === "AC") {
      setValue("");
    } else if (button === "DE") {
      setValue(value.slice(0, -1));
    } else if (button === "%") {
      setValue((parseFloat(value) / 100).toString());
    } else if (button === "=") {
      setValue(eval(value).toString());
    } else {
      if (value.length < 12) {
        setValue(value + button);
      }
    }
  };

  return (
    <div className="container">
      <div className="calculator">
        <Display value={value} />
        <Buttons onButtonClick={handleButtonClick} />
      </div>
    </div>
  );
}
